import React from 'react'
import Layout from 'layouts/en'
import { Button, Div } from 'components'

export default () => {
  return (
    <Layout p={5}>
      <Div p={[4,6]} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Button boxShadow={'primary'} bg={'primary'} color={'white'} href={`/`}>Retour sur la homepage</Button>
      </Div>
    </Layout>
  )
}
